<template>
  <div class="problem-container">
    <el-table
        :data="trainingSelect"
        stripe
        height="100%"
        border
        :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
        :header-cell-style="{
          color: 'rgba(19, 19, 27, 1)',
          background: '#F3F4FA',
          borderRight: 'unset',
        }"
      >
        <el-table-column
          prop="name"
          label="理论题库分类"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="train_name"
          label="训练名称"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="student_class_name"
          label="训练班级"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="start_time"
          label="开始时间"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="end_time"
          label="结束时间"
          width="260"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="options" label="操作" width="299" align="center">
          <template slot-scope="scope">
            <el-button @click="goToDetail(scope.row)" type="text" size="small"
              >评分</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    <el-pagination
        class="pages-center"
        :current-page="listPages.currentPageNum"
        :page-size="listPages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="listPages.total"
        @current-change="pageCurrentChange"
      >
    </el-pagination>
  </div>
</template>

<script>
import { showTheoryListData } from "@/utils/apis";
export default {
  name: "problemAnalysis",
  data() {
    return {
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      trainingSelect:[]
    };
  },
  mounted() {
    this.showProblemList();
  },
  methods: {
    showProblemList() {
      let params = {
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showTheoryListData(params).then((res) => {
        if(res.code===200){
            this.trainingSelect=res.data.list
            this.listPages.total=res.data.total
        }
      });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showProblemList();
    },
    goToDetail(row){
        this.$router.push({
          path:"/trainAdmin/problemAnalysis/problemStudentDetail",
          query:{
            pid:row.id
          }
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.problem-container {
  ::v-deep .el-button--text {
    color: rgba(17, 34, 216, 1);
  }
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .el-table {
    flex:1;
    margin-top:20px;
  }
  .pages-center {
    text-align: center;
    margin-top: 20px;
  }
}
</style>